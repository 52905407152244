.pokemon {
    width: 80vw;
    margin: 30px auto 60px auto;
    border: 3px solid black;
    border-radius: 20px;
    padding: 10px;
    color: rgb(37, 37, 37);
    text-align: center;
}

.pokemon header {
    font-size: 20px;
    padding: 20px;
}

.pokemon header h1 {
    font-size: 26px;
    text-align: center;
}

.pokemon .pokemonStats {
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
    border-bottom: 3px solid black;
    border-top: 3px solid black;
    text-align: left;
}

.pokemon .pokemonType {
    border-bottom: 3px solid black;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 24px;
    text-align: left;
}
.pokemon .pokemonType h2 {
    font-size: 28px;
    margin-bottom: 5px;
    margin-left: 5px;
    text-align: left;
}

.pokemon img {
    width: 80%;
    margin: auto;
}

.pokemon .pokemonProfile {
    text-align: left;
    border-top: 3px solid black;
    border-bottom: 3px solid black;
    padding: 10px;
}

.pokemon .pokemonProfile h2 {
    padding: 5px;
}

.pokemon .pokemonProfile h4 {
    margin-top: 5px;
}

.pokemon button {
    width: 20vw;
    height: 10vw;
    margin-top: 20px;
    margin-bottom: 10px;
    background-color: rgba(216, 216, 216, 0.5);
    border: 2px solid rgb(107, 107, 107);
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
}

.poison {
    color: whitesmoke;
}

.dark {
    color: whitesmoke;
}

@media (min-width: 1000px) {
    .pokemon {
        width: 90vw;
        font-size: 36px;
        margin-top: 6vh;
        position: relative;
    }

    .pokemon header {
        font-size: 36px;
        padding: 20px;
    }

    .pokemon header h1 {
        font-size: 42px;
        text-align: center;
    }

    .pokemon .pokemonStats {
        width: 100%;
        padding: 10px;
        margin-bottom: 5px;
        border-bottom: 3px solid black;
        border-top: 3px solid black;
        text-align: left;
    }

    .pokemon .pokemonType {
        border-bottom: 0;
        padding: 10px;
        margin-bottom: 10px;
        font-size: 36px;
        text-align: left;
    }

    .pokemon .pokemonType h2 {
        font-size: 36px;
        margin-bottom: 5px;
        margin-left: 5px;
        text-align: left;
    }

    .pokemon img {
        position: absolute;
        width: auto;
        height: 30%;
        margin: auto;
        top: 10%;
        right: 10%;
    }

    .pokemon .pokemonProfile h2 {
        font-size: 42px;
    }
    .pokemon .pokemonProfile h4 {
        font-size: 36px;
    }

    .pokemon button {
        width: 15vw;
        height: 8vw;
        margin-top: 20px;
        margin-bottom: 10px;
    }
}

@media (min-width: 1200px) {
    .pokemon {
        width: 70vw;
        font-size: 36px;
        margin-top: 6vh;
        position: relative;
    }
    .pokemon img {
        position: absolute;
        width: auto;
        height: 300px;
        margin: auto;
        top: 9vh;
        right: 10vw;
    }
    .pokemon button {
        width: 10vw;
        height: 5vw;
    }
}