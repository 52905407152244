:root {
    --btn-background-color: #ffbb00;
    --btn-hover-background-color: #ff7300;
}
.wrapper {
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-image: url('../img/pokedex-backgound.jpg');
    z-index: -1;
    background-position: center;
    background-size: cover;
}

.logo {
    display: block;
    background-color: var(--btn-background-color);
    border-radius: 20px;
    background-clip: text;
    color: transparent;
    width: 230px;
    padding-top: 5px;
    margin-left: 20px;
    font-size: 48px;
    letter-spacing: 1px;
}

#home {
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#home > h1 {
    background-color: orange;
    width: 100%;
    margin: 0;
    text-align: center;
    padding: 25px;
}
#home > .info {
    display: flex;
    flex-direction: column;
    padding: 25px;
    background-color: rgba(0,0,0, 0.7);
}
#home > .info > a {
    color: orange;
    padding-bottom: 3px;
    border-bottom: 3px solid orange;
    margin-left: auto;
    transition: all 0.5s ease;
}
#home > .info > a:hover {
    color: orangered;
    border-bottom: 3px solid orangered;
}
#home > .info > p {
    margin: 25px 0;
}

@media (min-width: 400px) {
    .logo {
        font-size: 42px;
    }
}

@media (min-width: 1000px) {
    .logo {
        font-size: 52px;
        width: 280px;
        margin-left: 30px;
    }
}

@media (min-width: 1200px) {
    .logo {
        font-size: 62px;
        width: 280px;
        margin-left: 30px;
    }
}