main > h2 {
    background-color: orange;
    width: 100%;
    margin: 0;
    text-align: center;
    padding: 25px;
    margin-bottom: 25px;
}
main > h2 > .regionName {
    color: orangered;
    padding-bottom: 3px;
    border-bottom: 3px solid orangered;
}
@keyframes region {
    0% {
        color: gold;
    }

    100% {
        color: rgb(226, 83, 0);
    }
}

main h2 .regionName {
    display: inline-block;
    text-transform: capitalize;
}
.pokemonCards {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 40px;
    background-color: white;
    mix-blend-mode: color;
}
#legend {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}
#legend span {
    display: block;
    width: 25px;
    height: 25px;
    left: 0;
    border: 5px solid #333;
    transition: all 0.5s ease;
}
#legend span.steel,
#legend span.ghost,
#legend span.dark {
    border-color: white;
    border-width: 1px;
    width: 15px;
    height: 15px;
    margin-left: 5px;
}
#legend > .legend-box {
    display: flex;
    align-items: center;
    position: relative;
    background-color:#333;
    margin: 5px;
    min-width: 90px;
    cursor: pointer;
    transition: all 0.5s ease;
}
#legend > .legend-box.active span {
    width: 35px;
    height: 35px;
}
#legend > .legend-box.active span.drak,
#legend > .legend-box.active span.ghost,
#legend > .legend-box.active span.steel {
    width: 30px;
    height: 30px;
}
#legend > .legend-box.active p {
    font-size: 25px;
}
#legend p {
    color: white;
    margin: 0;
    padding: 5px 15px;
    font-size: 14px;
    margin-left: auto;
    text-transform: uppercase;
    transition: all 0.5s ease;
}
#cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.pokemonCard {
    background-color: rgba(255, 255, 255);
    width: 70vw;
    color: black;
    text-align: center;
    margin-bottom: 20px;
    min-height: 70vw;
    box-shadow: 0 5px 15px -10px black;
}

.pokemonCard a {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.pokemonCard a > * {
    width: 100%;
    flex: auto;
}

.pokemonCard h3 {
    background-color: rgb(255, 227, 191);
    padding: 10px;
    font-size: 24px;
    text-transform: capitalize;
    letter-spacing: 1px;
    font-size: 16px;
    color: rgb(46, 46, 46);
}

.pokemonCard img {
    width: 60vw;
    image-rendering: crisp-edges;
    border-style: solid;
    border-width: 0 10px 10px 10px;
}

.loading img {
    margin-top: 10px;
    max-width: 70%;
}

@media (min-width: 400px) {

    .pokemonCard {
        width: 40vw;
        margin: auto;
        margin-bottom: 30px;
        min-height: 40vw;
    }

    .pokemonCard img {
        width: 40vw;
    }

    .pokemonCard h3 {
        letter-spacing: 0;
    }

}

@media (min-width: 750px) {
    main>h2 {
        font-size: 48px;
    }

    .pokemonCard {
        width: 30vw;
        margin: auto;
        margin-bottom: 30px;
        min-height: 30vw;
    }

    .pokemonCard img {
        width: 30vw;
    }

    .pokemonCard h3 {
        letter-spacing: 0;
    }
}

@media (min-width: 1000px) {

    .pokemonCard {
        width: 20vw;
        margin: 25px;
        margin-bottom: 30px;
        min-height: 20vw;
    }

    .pokemonCard img {
        width: 20vw;
    }

    .pokemonCard h3 {
        letter-spacing: 0;
    }
}

@media (min-width: 1200px) {

    .pokemonCard {
        width: 10vw;
        margin: 60px;
        margin-bottom: 30px;
        min-height: 12vw;
        border-radius: 10px;
    }

    .pokemonCard img {
        width: 10vw;
    }

    .pokemonCard h3 {
        letter-spacing: 0;
        border-radius: 5px 5px 0 0;
    }
}




.grass img {
    border-color: rgb(145, 255, 102);
}

.grass h3,
span.grass {
    background-color:  rgb(145, 255, 102);
}

.fire img {
    border-color: rgb(255, 84, 17);
}

.fire h3,
span.fire {
    background-color:rgb(255, 84, 17);
}

.water img {
    border-color: rgb(102, 194, 255);
}

.water h3,
span.water {
    background-color: rgb(102, 194, 255);
}

.bug img {
    border-color: rgb(45, 252, 114);
}

.bug h3,
span.bug {
    background-color: rgb(45, 252, 114);
}

.normal img {
    border-color: rgb(177, 177, 177);
}

.normal h3,
span.normal {
    background-color: rgb(177, 177, 177);
}

.poison img {
    border-color: rgb(136, 35, 252);
}

.poison h3,
span.poison {
    background-color: rgb(136, 35, 252);
    color: rgb(226, 225, 225);
}

.electric img {
    border-color: rgb(255, 251, 11);
}

.electric h3,
span.electric {
    background-color:  rgb(255, 251, 11);
}

.ground img {
    border-color: rgb(180, 117, 0);
}

.ground h3,
span.ground {
    background-color: rgb(180, 117, 0);
}

.fairy img {
    border-color: rgb(255, 104, 242);
}
.fairy h3,
span.fairy {
    background-color: rgb(255, 104, 242);
}

.fighting img {
    border-color: rgb(255, 13, 13);
}

.fighting h3,
span.fighting {
    background-color: rgb(255, 13, 13);
    color: white;
}

.psychic img {
    border-color: rgb(255, 63, 153);
}

.psychic h3,
span.psychic {
    background-color: rgb(255, 63, 153);
    color: white;
}

.rock img {
    border-color: rgb(102, 89, 30);
}

.rock h3,
span.rock {
    background-color: rgba(110, 88, 27);
    color: rgb(255, 255, 255);
}

.ghost img {
    border-color: rgb(63, 22, 129);
}

.ghost h3,
span.ghost {
    background-color: rgb(63, 22, 129);
    color: rgb(255, 255, 255);
}

.ice img {
    border-color: rgb(93, 253, 245);
}

.ice h3,
span.ice {
    background-color: rgb(93, 253, 245);
    color: rgb(73, 73, 73);
}

.dragon img {
    border-color: rgb(90, 72, 253);
}

.dragon h3,
span.dragon {
    background-color: rgb(90, 72, 253);
    color: rgb(255, 255, 255);
}

.dark img {
    border-color: rgb(20, 11, 24);
}

.dark h3,
span.dark {
    background-color: rgb(20, 11, 24);
    color: rgb(255, 255, 255);
}

.flying img {
    border-color: rgb(144, 250, 253);
}

.flying h3,
span.flying {
    background-color: rgb(144, 250, 253);
    color: rgb(66, 66, 66);
}

.steel img {
    border-color: #373737;
}

.steel h3,
span.steel {
    background-color: #373737;
    color: white;
}