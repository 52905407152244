* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  color: white;
}

a {
  color: white;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
}