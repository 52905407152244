footer h1 {
    display: block;
    position: fixed;
    bottom: 0px;
    width: 100vw;
    font-size: 20px;
    text-align: center;
    background-color: rgba(21, 21, 24, 0.85);
    height: 50px;
    line-height: 50px;
    color: white;
    letter-spacing: 2px;
}