:root {
    --btn-background-color: #ffbb00;
    --btn-hover-background-color: #ff7300;
}

header {
    display: flex;
    align-items: center;
    width: 100vw;
}

header nav {
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: 25px;
}

header nav ul {
    width: 95%;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    top: 25px;
    margin: auto;
}

header nav ul li {
    display: block;
    box-sizing: border-box;
    list-style: none;
    width: auto;
    margin: 0 10px;
    transform: skewX(-10deg);
    background-color:   var(--btn-background-color);
    text-align: center;
    flex: auto;
}

header nav ul li a {
    display: block;
    width: 100%;
    min-height: 100%;
    letter-spacing: 1px;
    font-weight: bold;
    padding: 25px 30px;
    transition: all 0.5s ease;
    position: relative;
    overflow: hidden;
}
header nav ul li a:after {
    content: '';
    display: block;
    width: 100%;
    height: 60px;
    background-color: var(--btn-hover-background-color);
    position: absolute;
    bottom: -60px;
    left: 0;
    transition: all 0.5s ease;
    z-index: -1;
}

header nav ul li:hover {
    cursor: pointer;
}

header nav ul li:hover a:after,
header nav ul li a.active:after {
    bottom: -25px;
    transform: skewY(-5deg);
}

@keyframes shine {
    0% {}
}

@media (min-width: 1200px) {
    header nav ul li {
        font-size: 18px;
    }

    header nav ul li a {
        min-width: 100%;
        min-height: 100%;
    }
}
@media (max-width: 1200px) {
    header {
        flex-direction: column;
    }
    header > nav {
        margin: auto auto 50px auto;
    }
    header > nav > ul {
        justify-content: center;
    }
    header > nav > ul > li {
        margin: 5px;
    }
    header > nav > ul > li > a {
        padding: 5px 20px;;
    }
    header nav ul li a.active:after {
        bottom: -45px;
    }
}